import { ContentArea } from '@local/content-area';
import { getAdminFromBentleyAccessToken } from '@local/login';
import { getCurrentUserDetails } from '@local/login/dist/store/sessionStorageHelpers/entitlementsHelper/entitlementsHelper';
import { MaintenanceState } from '@local/svgs/dist/pageState';
import { WithTerms } from '@local/terms-of-use';
import { ConnectivityCheck } from '@local/web-design-system/dist/components/ConnectivityCheck';
import type { NavDrawerItem } from '@local/web-design-system/dist/components/NavDrawer';
import { WORKSPACES_LIST_PAGE } from '@local/workspaces/dist/urls';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import Grid from '@mui/material/Grid/Grid';
import classNames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { SEARCH_PAGE, USER_MANAGEMENT_PAGE, WEBVIZ_VIEWER, WORKSPACE_RECYCLE_BIN } from 'src/urls';

import { EvoIcon } from '../../assets/EvoBadge';
import { NO_INTERNET_CONNECTION, TAGLINE, VERSION_LABEL, SCAFFOLDING_TITLE } from '../../strings';
import { useStyles } from './ContentScaffolding.styles';
import { HubSelector } from './HubSelector/HubSelector';
import { ViewerBreadcrumb } from './ViewerBreadcrumb';

export const ContentScaffolding = () => {
    const { evouiEnableDiscover, evouiMaintenancePage, evouiEnableDelete } = useFlags();
    const { classes } = useStyles();
    const location = useLocation();
    const params = useParams();

    const user = getCurrentUserDetails();
    const isAdmin = getAdminFromBentleyAccessToken();

    setDocumentTitle(SCAFFOLDING_TITLE);

    const navItems: NavDrawerItem[] = [
        {
            key: 'workspaces-listing',
            text: 'Workspaces',
            location: `/${params.orgUuid}/${WORKSPACES_LIST_PAGE}`,
            icon: 'workspace',
        },
    ];

    if (evouiEnableDiscover) {
        navItems.push({
            key: 'search',
            text: 'Search',
            location: `/${params.orgUuid}/${SEARCH_PAGE}`,
            icon: 'map',
        });
    }

    if (evouiEnableDelete) {
        navItems.push({
            key: 'recyclebin',
            text: 'Recycle Bin',
            location: `/${params.orgUuid}/${WORKSPACE_RECYCLE_BIN}`,
            icon: 'moveToBin',
        });
    }

    navItems.push({
        key: 'user-management',
        text: 'Administrators',
        location: `/${params.orgUuid}/${USER_MANAGEMENT_PAGE}`,
        icon: 'users',
    });

    const footerComponent = !location.pathname.includes(WEBVIZ_VIEWER) ? (
        <Grid automation-id="user-portal-version" className={classes.footer}>
            {TAGLINE}&nbsp;&nbsp;&nbsp;{VERSION_LABEL}
        </Grid>
    ) : undefined;

    return (
        <>
            {evouiMaintenancePage && <MaintenanceState appName="Evo" />}
            <div style={{ display: evouiMaintenancePage ? 'none' : undefined }}>
                <ContentArea
                    currentRoute={location.pathname}
                    evoSelector={<HubSelector />}
                    logoOnly={
                        <div className={classes.appLogo}>
                            <EvoIcon />
                        </div>
                    }
                    logoWithTitle={
                        <div className={classes.appLogoLong}>
                            <img src="/SeequentEvoWhite.svg" alt="SeequentEvo" />
                        </div>
                    }
                    footerComponent={footerComponent}
                    navItems={navItems}
                    service="evo"
                    // TODO: Change the type of userResult to User instead of LoadingHookUser
                    userResult={user}
                    isAdmin={Boolean(isAdmin)}
                    variant="light"
                    exactMatch={false}
                    breadcrumbs={
                        location.pathname.includes(WEBVIZ_VIEWER) && params.workspaceUuid ? (
                            <ViewerBreadcrumb />
                        ) : null
                    }
                >
                    <div
                        className={classNames(classes.contentInner, {
                            [classes.webVizContent]: location.pathname.includes(WEBVIZ_VIEWER),
                        })}
                    >
                        <WithTerms appName="Evo">
                            <ConnectivityCheck offlineMessage={NO_INTERNET_CONNECTION} />
                            <Outlet />
                        </WithTerms>
                    </div>
                </ContentArea>
            </div>
        </>
    );
};

export default ContentScaffolding;
